.kpi-outter-head {
  padding-bottom: 40px;
  background-color: rgb(240, 242, 245);
}

.kpi-item-outter {
  background-color: #ffffff;
}

.btn-add-product {
  position: fixed;
  bottom: 53px;
  left: 30px;
  z-index: 2;
  box-shadow: #00000069 2px 3px 4px;
}

.btn-export {
  position: fixed;
  bottom: 53px;
  left: 30px;
  z-index: 2;
  box-shadow: #00000069 2px 3px 4px;
}

.brukere-table {
  position: relative;
}

.brukere-table .ant-pagination {
  margin-right: 1rem;
  position: absolute;
  right: 0;
  bottom: 0px;
}

.brukere-table .ant-table-row:hover {
  cursor: pointer;
}

.logo:hover {
  cursor: pointer;
}

.order-flex-container {
  display: flex;
  width: 100%;
}

.order-left-container {
  overflow: auto;
}

.order-right-container {
  position: relative;
  width: 150%;
}

.card-pagination .ant-card-body {
  padding: 5px;
  display: flex;
  justify-content: space-between;
}

.filter-card {
  max-height: 0;
  overflow: hidden;
  transition: max-height 500ms ease-in-out;
}

.filter-card.visible {
  max-height: 500px;
  box-shadow: #ccc 0px 3px 3px;
}

.report-card-container .ant-card-body {
  padding: 5px;
}

.report-card-container .ant-card-body .ant-row {
  padding: 13px;
}

.btn-search-mode {
  color: #aaa;
}

@media (max-width: 765px) {
  .uos-layout .ant-layout-content {
    padding: 38px 12px 0px 5px !important;
  }
}
