.related-buttons {
  margin: 0 0.5rem;
}

.related-buttons:first-child {
  margin-right: 0.5rem;
}

.related-buttons:last-child {
  margin-left: 0.5rem;
}
